html {
    font-size: 62.5%;
}

* {
    backface-visibility: hidden;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

*::selection {
    background: rgb(0, 141, 229);
    color: #fff;
}

a {
    color: rgba(0, 157, 255, 0.5);
    font-weight: 600;
    text-decoration: none;
}
a:hover {
    color: rgba(255, 0, 119, 0.5);
    text-decoration: none;
}

body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    font-family: 'DMSans', calibri, tahoma;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.5rem;
}

.ck.ck-editor {
    width: 100%;
    max-width: 900px;
}
.ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items {
    flex-wrap: wrap;
}

@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
}

@font-face {
    font-family: 'DMSans';
    font-weight: 400;
    src: local('DMSans'),
        url(./assets/fonts/DMSans-Regular.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'DMSans';
    font-weight: 600;
    src: local('DMSans'),
        url(./assets/fonts/DMSans-Medium.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'DMSans';
    font-weight: 800;
    src: local('DMSans'), url(./assets/fonts/DMSans-Bold.ttf) format('truetype');
    font-display: swap;
}
